require("@rails/ujs").start()

// import customSelect from 'custom-select';
// var customSelect = require("custom-select").default;



// import $ from 'jquery'
// import 'select2'






import "stylesheets/application.scss";










